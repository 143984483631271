.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  margin-top: 0.83em;
}

.App-logo svg {
	width: auto;
	height: 100%;
	fill: #67daf9;
}